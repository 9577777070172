.carouselItem {
  width: 140px !important;
  height: 240px !important;
  display: block !important;
  margin-top: 40px !important;
  margin: auto;
}

.carouselItem__img {
  width: 100%;
  height: 80% !important;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
}

.carouselItem__txt{
    font-size: .9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.slick-slider{
  height: 100%;
}

.slick-slider  .slick-dots li button:before {
  color: #9233ff !important;
  margin-top: 10px;
}
