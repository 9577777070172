@import url("https://fonts.googleapis.com/css2?family=DynaPuff:wght@600&family=Heebo:wght@200;400;600&family=Inter:wght@400;500&family=Lato:wght@400;900&family=Nunito:wght@700&family=Poppins:wght@200;300;400;500&family=Roboto:wght@300;400&family=Urbanist:wght@300&display=swap");

:root {
  --main: #0f2862;
  --second: #9e363a;
  --third: #091f36;
  --fourth: #4f5f76;

  --primary-color: #7b0b0b;
  --card-bg: #acabab93;
  --hero-bg: #7b0b0b5e;
  --heading-fontSize: 2rem;
  --small-text-color: #999;
  --heading-text-color: #ffffff;
}

* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}

p {
  color: var(--small-text-color);
  font-size: 1rem;
}

h1,
h2 {
  font-size: var(--heading-fontSize);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: unset;
}

img {
  width: 100%;
}

section {
  padding: 60px 0px;
}

.container__title {
  text-align: center;
  color: var(--main);
  font-size: 2rem;
  margin: 0px 0px 30px 0;
}

h3 a {
  font-size: 1rem !important;
}
