@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");

.ContentModal__normal {
  flex-direction: column !important;
  text-align: center;
  align-items: center;
}

.ContentModal__portrait {
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100% !important;
  height: 100% !important;
  display: none !important;
}

.video-container {
  margin: 20px 0;
  display: flex;
  align-items: end;
  height: 40% !important;
}
.video-container iframe {
  height: 100% !important;
  width: 100% !important;
}

.tagline {
  padding-bottom: 10px;
  align-self: center;
  text-align: center;
}

.ContentModal {
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
}

.ContentModal::-webkit-scrollbar {
  display: none;
}

.ContentModal__about {
  margin-top: 60px;
  padding: 10px !important;
  width: 100%;
  height: 90%;
  display: flex;
  margin: 130px auto;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  justify-content: space-evenly;
  font-weight: 300;
}

.ContentModal__title {
  height: 12%;
  font-size: 1.5rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.ContentModal__description {
  display: flex;
  height: 80% !important;
  overflow-y: scroll;
  padding: 15px;
  border-radius: 10px;
  scrollbar-width: thin; /* Firefox */
  box-shadow: inset 0 0 5px #000000;
  text-align: justify;
}

.ContentModal__description::-webkit-scrollbar {
  display: none;
}

.genres__section {
  margin: 10px 0;
  width: 100%;
  display: flex !important;
  align-items: center !important;
  justify-content: space-around;
}

@media (min-width: 834px) {
  .ContentModal__landscape {
    display: none !important;
  }
  .ContentModal__portrait {
    display: block !important;
  }
  .ContentModal {
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0;
  }
  .ContentModal__about {
    width: 70%;
    padding: 0;
    height: 100%;
  }
  .ContentModal__title {
    font-size: 3.5vw;
  }
  .ContentModal__description {
    font-size: 22px;
  }
}

@media only screen and (max-width: 540px) {
  .genres__section {
    font-size: .7rem;
    flex-direction: column;
  }

  .genres__section span{
    padding: 10px ;
  }

  .ContentModal__description {
    padding: 10px 0 60px 0;
  }
}
