.search-page {
  height: 30vh;
}

.sticky__search {
  width: 30%;
  /* height: 100px; */
  background: #ffffff9f;
  display: flex;
  margin: 10px auto  !important;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 80px;
  position: fixed !important;
  top: 100px !important;
  /* left: 0px; */
  z-index: 99999;
  box-shadow: 3px 3px 8px -4px #282828;
  transition: 0.5s;

  animation: stickysearch 0.6s;
}

@keyframes stickysearch {
  from {
    opacity: 0;

  }
  to {
    opacity: 1;
  }
}
