.section{
  margin-top: 50px;
  display: flex;
  margin: auto;
}

.item__position{
    display: flex;
    align-items: center;
    justify-content: center;
}
/* .container__title{
  text-align: center;
  color: var(--main);
  font-size: 3rem;
  margin: 0px 0px 30px 0;
} */