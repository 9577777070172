.movie {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 10px;
  height: 300px;
  width: 200px !important;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 10px;
}

.movie__img img {
  width: 100%;
  transition: 3s;
}

.info {
  background: rgba(20, 20, 20, 0.646);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 10px;
  color: #fff;
  animation: infomove 0.4s;
  display: none;
}

@keyframes infomove {
  from {
    transform: translateY(100%);
    background: rgb(0, 0, 0);
  }
  to {
    transform: translateY(0%);
    background: rgba(20, 20, 20, 0.646);
  }
}

.movie__item:hover .info {
  display: block;
}

.movie__name a,
.movie__name a:hover {
  text-decoration: double;
  color: #fff;
  font-size: 1.8rem;
}

.green{
  color: green;
  font-size: 1.4rem;
}

.red{
  color: red;
  font-size: 1.4rem;
}