section {
  transition: 2s;
}

.section__name-light{
  margin-top: 30px;
  color: rgba(59, 18, 73, 1);
  font-size: 2.4rem;
  font-weight: 600;
  letter-spacing: 5px;
  transition: 2s;
}

.section__name-dark {
  margin-top: 30px;
  color: rgb(176, 94, 169);
  font-size: 2.4rem;
  font-weight: 600;
  letter-spacing: 5px;
  transition: 2s;
}

.carousel__section-dark {
  border-radius: 10px;
  /* background: #091f36; */
  background: rgb(9, 6, 54);
  background: linear-gradient(
    90deg,
    rgba(9, 6, 54, 1) 0%,
    rgba(59, 18, 73, 1) 47%,
    rgba(130, 25, 121, 1) 100%
  );
  /* transition: 1s; */

  box-shadow: 0px 0px 49px 2px rgba(255, 255, 255, 0.77);
  -webkit-box-shadow: 0px 0px 49px 2px rgba(255, 255, 255, 0.77);
  -moz-box-shadow: 0px 0px 49px 2px rgba(255, 255, 255, 0.77);
  padding: 25px 30px!important;
}

.carousel__section-light {
  border-radius: 10px;
  /* background: #9e363a; */

  background: rgb(147, 141, 241);
  background: linear-gradient(
    90deg,
    rgba(147, 141, 241, 1) 0%,
    rgba(162, 93, 185, 1) 47%,
    rgba(204, 65, 192, 1) 100%
  );
  /* transition: 1s; */

  box-shadow: 0px 0px 49px 2px rgba(0, 0, 0, 0.77);
  -webkit-box-shadow: 0px 0px 49px 2px rgba(0, 0, 0, 0.77);
  -moz-box-shadow: 0px 0px 49px 2px rgba(0, 0, 0, 0.77);
  padding: 25px 30px!important;
}

.carousel__section-dark .slick-dots li button:before {
  color: #fff !important;
}

.carousel__section-light .slick-dots li button:before {
  color: black !important;
}


/*  */


.movie__section{
  margin-top: 100px;
}

@media only screen and (max-width: 600px){
  .movie__section h3{
    font-size: 1.5rem;
  }

  .carousel__section-light {
   width: 90% !important;
  }

  .carousel__section-dark {
    width: 90% !important;
    margin: auto;
   }

}


@media only screen and (max-width: 230px){
  .movie__section h3{
    font-size: 1.2rem;
  }
}