section{
  transition: 2s !important;
}

.header {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  text-align: center;
  line-height: 60px;
  box-shadow: 0px 7px 11px -1px rgba(0, 0, 0, 0.66);
  -webkit-box-shadow: 0px 7px 11px -1px rgba(0, 0, 0, 0.664);
  -moz-box-shadow: 0px 7px 11px -1px rgba(0, 0, 0, 0.692);
  background: rgb(46, 45, 45);
  background: linear-gradient(
    90deg,
    rgba(46, 45, 45, 0.8575805322128851) 0%,
    rgba(28, 27, 27, 0.7903536414565826) 46%,
    rgba(48, 48, 48, 0.8295693277310925) 98%
  );
  /* overflow: hidden; */
}

.logo img {
  width: 50px;
  /* height: 120px; */
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

/* navigation */
.nav__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}

.menu {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4rem;
}

.menu .nav__item {
  list-style: none;
}
.menu .nav__item a:hover {
  list-style: none;
  color: #1a89e4c8;
}

.menu .nav__item a {
  font-size: 1.4rem;
  transition: 0.5s;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
}

.nav__active {
  color: #1a89e4c8 !important;
  font-weight: 600 !important;
  font-size: 1.45rem !important;
}

/*  */

.nav__item {
  text-decoration: none;
  position: relative;
}

.nav__item a {
  font-size: .9rem !important;
}

.nav__item a:after {
  position: absolute;
  content: "";
  height: 2px;
  bottom: 15px;

  margin: 0 auto;
  left: 0;
  right: 0;
  width: 1%;
  background: #fff;

  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.3s;
}

/* optional hover classes used with anmiation */
.nav__item a:hover:after {
  width: 90%;
  background: #1a89e4c8;
}

.nav__icons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

/*  search */

.searchBox {
  transform: translate(0%, 0%);
  background: var(--third);
  height: 40px;
  border-radius: 20px;
  padding: 5px;
  transition: 0.3s;
}

.nav__icons:hover > .searchBox {
  background: var(--main);
}

.searchBox:hover > .searchInput {
  width: 110px;
  padding: 0 3px;
}

.searchBox:hover > .searchButton {
  background: white;
  color: var(--third);
}

.searchButton {
  color: white;
  float: right;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #2f3640;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
}

.searchInput {
  border: none;
  background: none;
  outline: none;
  float: left;
  padding: 0;
  color: white;
  font-size: 1.5rem;
  transition: 0.4s;
  line-height: 0px;
  letter-spacing: 0.4px;
  margin-top: 3px;
  width: 0px;
}

.menubar {
  color: #fff;
  padding: 3px;
  cursor: pointer;
  font-size: 2rem;
  display: none;
}

.lightbar {
  color: #fff;
  padding: 2px;
  cursor: pointer;
  font-size: 2rem;
  box-shadow: 0px -1px 40px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px -1px 40px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px -1px 40px 0px rgba(0, 0, 0, 0.75);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.section {
  background: rgb(46, 45, 45);
  background: linear-gradient(
    90deg,
    rgba(46, 45, 45, 0.8575805322128851) 0%,
    rgba(28, 27, 27, 0.7903536414565826) 46%,
    rgba(48, 48, 48, 0.8295693277310925) 98%
  );
}
.lightbar {
  transition: 0.3s;
}

.sticky__header {
  width: 100%;
  height: 100px;
  line-height: 80px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  box-shadow: 3px 3px 8px -4px #ddd;
  transition: 0.5s;

  animation: stickyheader 0.9s;
}

@keyframes stickyheader {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.dark-toggle {
  /* background: rgb(46, 45, 45);
  background: linear-gradient(
    90deg,
    rgba(46, 45, 45, 0.8575805322128851) 0%,
    rgba(28, 27, 27, 0.7903536414565826) 46%,
    rgba(48, 48, 48, 0.8295693277310925) 98%
  ); */

  background: rgb(106, 11, 107);
  background: radial-gradient(
    circle,
    rgba(106, 11, 107, 0.8995973389355743) 0%,
    rgba(57, 8, 93, 0.8491771708683473) 48%,
    rgba(101, 34, 105, 0.9640231092436975) 100%
  );
}

.light-toggle {
  /* background: rgb(233, 233, 233); */
  /* background: radial-gradient(
    circle,
    rgba(167, 167, 167, 0.692) 0%,
    rgba(165, 165, 165, 0.734) 20%,
    rgba(135, 133, 133, 0.83) 85%
  ); */

  background: rgb(144, 144, 149);
  background: radial-gradient(
    circle,
    rgba(144, 144, 149, 1) 0%,
    rgba(141, 114, 161, 1) 51%,
    rgba(167, 156, 166, 1) 100%
  );
}

/* responsive */

@media screen and (max-width: 620px) {
  .searchBox:hover > .searchInput {
    width: 150px;
    padding: 0 6px;
  }
}

@media screen and (max-width: 740px) {
  .header {
    height: 70px;
  }

  .nav__wrapper{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin: auto;
  }
  .menubar {
    display: block;
    z-index: 9999;
  }

  .lightbar {
    width: 50px;
    height: 37px;
    margin-right: 10px;
    font-size: 1.6rem;
  }

  .menubar span i {
    position: fixed;
    top: 0;
    right: 50px;
  }

  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.445);
    z-index: 9;
    display: none;
  }
  .menu {
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    width: calc(100% - 40%);
    z-index: 999;
    flex-direction: column;
    background: var(--third);
    animation: menumove 0.4s;
  }

  .active__menu {
    display: block;
    display: flex;
  }
  
  @keyframes menumove {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0%);
    }
  }

  .header {
    display: block;
  }

  .nav__wrapper {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    text-align: center !important;
  }

  .logo {
    width: 20%;
  }

  .nav__icons {
    width: 60%;
  }

  .menubar {
    width: 20%;
  }

  .searchBox:hover > .searchInput {
    width: 200px;
    padding: 0 3px;
  }
}

@media screen and (max-width: 768px) {
  .logo img {
    width: 35px;
  }
  .menu .nav__item a {
    font-size: .7rem !important;
  }
}

@media screen and (max-width: 767px) {
  .nav__wrapper {
    width: 100% !important; 
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 3rem;
  }
}

@media screen and (max-width: 635px) {
  .nav__wrapper {
    column-gap: 3rem;
  }
}

@media screen and (max-width: 464px) {
  .searchBox:hover > .searchInput {
    width: 100px;
    padding: 0 3px;
  }
}

@media screen and (max-width: 375px) {
  .nav__item a {
    font-size: 1rem !important;
  }
}
@media screen and (max-width: 300px) {
  .searchBox {
    height: 30px;
    border-radius: 20px;
    padding: 1px;
  }

  .searchBox:hover > .searchInput {
    width: 80px;
    padding: 0 3px;
  }

  .searchBox:hover > .searchButton {
    background: white;
    color: var(--third);
  }

  .searchButton {
    color: white;
    float: right;
    width: 29px;
    height: 29px;
    background: #2f3640;
  }

  .searchInput {
    border: none;
    background: none;
    outline: none;
    float: left;
    padding: 0;
    color: white;
    font-size: 1.3rem;
    transition: 0.4s;
    line-height: 0px;
    letter-spacing: 0.4px;
    margin-top: 4px;
    width: 0px;
  }

  .lightbar {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 246px) {
  .searchBox:hover > .searchInput {
    width: 40px;
    padding: 0 3px;
  }
  .searchButton {
    color: white;
    width: 25px;
    height: 25px;
    background: #2f3640;
  }

  .logo img{
    width: 25px;
  }
  .lightbar {
    width: 87px !important;
    height: 25px;
    margin-right: 50px;
    font-size: 1rem;
  }
  .menubar{
    font-size: 1.3rem;
  }
}
